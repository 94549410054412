import { FlexBox } from 'modules/shared/components/FlexBox'
import { SimpleTaskListHeader } from 'modules/task/taskList/components/SimpleTaskListHeader'
import { ButtonSwitchType } from 'modules/task/taskList/components/ViewSwitch/TypeViewSwithMap'
import React from 'react'
import { TasksFilterComponentProps } from '../TypeTaskFilterMap'
import { useSelector } from 'react-redux'
import { selectLocationsByIds } from 'store/modules/hierarchy/hierarchySelectors'
import { ViewSwitch } from 'modules/task/taskList/components/ViewSwitch/ViewSwitch'
import { LocationTagFilter } from './locationTask/LocationTagFilter'
import { LocationDateRangeFilter } from './locationTask/LocationDateRangeFilter'

export const LocationTreeFilter: React.FC<TasksFilterComponentProps> = ({ locationId }) => {
  const location = useSelector(selectLocationsByIds)[locationId];

  return (
    <FlexBox flexDirection='row' flexWrap='wrap' alignItems='center'>
      <SimpleTaskListHeader regionName={location?.name} />
      <ViewSwitch type={ButtonSwitchType.ParentTasks} />
      <LocationTagFilter />
      <LocationDateRangeFilter />
    </FlexBox>
  )
}
