import { makeStyles, Theme } from '@material-ui/core/styles';
import {  Breakpoints } from 'utils/styles/constants';

export const useDateRangeInputStyles = makeStyles((theme: Theme) => ({
    container: {
        maxHeight: 36,
        position: 'relative',
        top: -4,
        [theme.breakpoints.down(860)]: {
            maxHeight: 50,
        },
        [theme.breakpoints.between(1024,1394)]: {
            maxHeight: 50,
        }
    },
    title:{
        position: "relative",
        top: 0,
        [theme.breakpoints.up(Breakpoints.Tablet)]: {
            position: "absolute",
            top: -20,
        },
        [theme.breakpoints.between(1024,1394)]: {
            position: "relative",
            top: 0,
        }
    },
    containerInput:{
        maxWidth: 190,
        [theme.breakpoints.between(Breakpoints.Tablet, 1092)]: {
            maxWidth: 190,
        },
        [theme.breakpoints.down(Breakpoints.Tablet)]: {
            width: '45%',
            '& input':{
                fontSize: 14
            }
        },        
    }
}));
