import { combineReducers } from 'redux';
import { CALENDAR_CALENDAR_FILTER_LOCATION_IDS, CALENDAR_FILTER_RESET, CALENDAR_FILTER_STATUS, CALENDAR_FILTER_TAGS, CalendarFilterActions } from './calendarFilterTaskActions';
import { AutomationTaskStatusFilter } from 'api/task/automationTask/automationTaskModel';

const initialState = {
  tags: [],
  locationIds: [],
  status: null,
};

export function locationIds(
  state: string[] = initialState.locationIds, action: CalendarFilterActions,
): string[] {
  switch (action.type) {
    case CALENDAR_FILTER_RESET:
      return [];
    case CALENDAR_CALENDAR_FILTER_LOCATION_IDS:
      return action.payload;
    default:
      return state;
  }
}

export function tags(
  state: string[] = initialState.tags, action: CalendarFilterActions,
): string[] {
  switch (action.type) {
    case CALENDAR_FILTER_RESET:
      return [];
    case CALENDAR_FILTER_TAGS:
      return action.payload;
    default:
      return state;
  }
}

export function status(
  state: AutomationTaskStatusFilter | null = initialState.status, action: CalendarFilterActions,
): AutomationTaskStatusFilter | null {
  switch (action.type) {
    case CALENDAR_FILTER_RESET:
      return null;
    case CALENDAR_FILTER_STATUS:
      return action.payload;
    default:
      return state;
  }
}

export const calendarFilterReducer = combineReducers({
  locationIds,
  tags,
  status
});
