import React from 'react';
import { TasksFilterComponentProps } from '../TypeTaskFilterMap';
import { Box } from '@material-ui/core';
import { CollapseBox } from 'modules/connect/messenger/components/ChatHeader/chatActions/InviteAction/CollapseBox';
import { CalendarTagFilter } from './calendar/CalendarTagFilter';
import { CalendarHeader } from './calendar/CalendarHeader';

export const CalendarStoreFilter: React.FC<TasksFilterComponentProps> = ({ locationId }) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const handleChangeCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <>
      <CalendarHeader locationId={locationId} handleChangeCollapse={handleChangeCollapse} collapsed={collapsed}/>
      <CollapseBox show={collapsed}>
        <Box width="100%" mt={2}>
          <CalendarTagFilter />
        </Box>
      </CollapseBox>
    </>
  )
}
