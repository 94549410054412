import { makeStyles, Theme } from "@material-ui/core";
import { BlueColor, UtilColors } from "utils/styles/constants";

export const useTaskFilterStyles = makeStyles((theme: Theme) => ({
  statusFilter: {
    width: '100%',
    maxWidth: '205px',
    display: 'flex',
    justifyContent: 'center'
  },
  select: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    lineHeight: '24px',
  },
  containerLeft:{
    marginLeft: 'auto',
  },
  buttonFilter: {
    width: 35,
    height: 35,
    "&.active": {
      color: BlueColor.Blue0,
      textDecoration: 'none',
      backgroundColor: UtilColors.HoverColor,
    },
    '&:hover': {
      backgroundColor: UtilColors.HoverColor,
    }
  },
}));
